import './Post.css'
import { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getPost, updatePost } from '../../utility/APImanager';

export const PostEdit = props => {

    const [post, setPost] = useState({})

    let history = useHistory()

    const mainRef = useRef()

    useEffect(() => {
        mainRef.current.style.height = `${window.innerHeight - 160}px`

        getPost(props.match.params.postId).then(res => {
            setPost(res)
        })
    }, [props.match.params.postId])

    const handleChange = event => {
        const newPost = {
            ...post
        }
        newPost[event.target.id] = event.target.value
        setPost(newPost)
    }

    const handleSubmit = () => {
        updatePost(
            props.match.params.postId,
            post.title,
            post.content
        )    
        redirect('/')
    }

    const redirect = (location, id) => {
        if(id) {
            history.push('')
            history.push(`${location}/${id}`)
        } else {
            history.push(location) 
        }
    }

    return (
        <div className='postCreateMain' ref={mainRef}>
            <div className='postHeader'>
                <div
                    id='backArrow'
                    className='postHeaderBackIcon' 
                    onClick={() => redirect('/')}
                >
                    <ArrowBackIcon />
                </div>
                <div className='postHeaderTitle'>Edit your post</div>
            </div>
            <input
                type='text'
                id='title'
                className='postCreateTitle-input'
                defaultValue={post.title}
                onChange={handleChange}
            ></input>
            <textarea
                id='content'
                className='postCreateContent-input'
                defaultValue={post.content}
                onChange={handleChange}
            ></textarea>
            <button
                className='postCreate-submitBtn'
                onClick={handleSubmit}
            >Save</button>
        </div>
    )
}