import { useState, useEffect } from 'react'
import { getPosts, deletePost } from '../../utility/APImanager'
import { Post } from './Post'

export const PostList = props => {

    const [postList, setPostList] = useState([])

    useEffect(() => {
        getPosts().then(res => {
            setPostList(res)
        })
    }, []) 

    const handlePostDelete = (postId) => {
        const confirmedDelete = window.confirm('Are you sure you want to delete this post?')
        if(confirmedDelete) {
            deletePost(postId)
            setPostList(postList.filter(post => post.id !== postId))
        }
    }

    return (
        <div className='postListMain'>
            {postList.map((post, index) => {
                return <Post 
                            key={index} 
                            post={post} 
                            user={props.user}
                            handlePostDelete={handlePostDelete}
                        />
            })}
        </div>
    )
}