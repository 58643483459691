import Parse from 'parse/dist/parse.min.js'

export const loginUser = async (user) => {
    const resp = await fetch(`https://g2g-api.com/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Origin: 'https://gear2go-blog.netlify.app/'
      },
      body: JSON.stringify(user),
    })
    return resp.json()
}

export const getPost = async (id) => {
    const postQuery = new Parse.Query('Post')

    postQuery.contains('objectId', id)

    const userQuery = new Parse.Query('AuthUser')     

    postQuery.matchesQuery('user', userQuery)
    postQuery.include('user')

    const results = await postQuery.first()
    
    const postData = {
        id: results.id,
        author: `${results.get('user').get('firstName')} ${results.get('user').get('lastName')}`,
        date: results.get('createdAt').toISOString(),
        title: results.get('title'),
        content: results.get('content')
    }

    return postData 
}

export const getPosts = async () => {
    // get all posts with author username

    const postQuery = new Parse.Query('Post')

    const userQuery = new Parse.Query('AuthUser')     

    postQuery.matchesQuery('user', userQuery)
    postQuery.include('user')

    const results = await postQuery.find()

    const postList = []
    results.forEach(result => {
        postList.push(
            {
                id: result.id,
                author: `${result.get('user').get('firstName')} ${result.get('user').get('lastName')}`,
                authorId: result.get('user').id,
                date: result.get('createdAt').toISOString(),
                title: result.get('title'),
                content: result.get('content')
            }
        )
    })

    return postList 
}

export const createPost = async (userId, title, content) => {
    let userQuery = new Parse.Query('AuthUser')

    userQuery.contains('objectId', userId)

    const user = await userQuery.first()

    const post = new Parse.Object('Post')

    post.set('user', user)
    post.set('title', title)
    post.set('content', content)

    try {
        await post.save()
        console.log('post was saved')
    } catch (error) {
        console.log('error: ', error.message)
    }
}

export const updatePost = async (id, title, content) => {
    const post = new Parse.Object('Post');

    post.set('objectId', id)
    post.set('title', title)
    post.set('content', content)

    try {
        await post.save()
        console.log('post was saved')
    } catch (error) {
        console.log('error: ', error.message)
    }
}

export const deletePost = async (id) => {
    const post = new Parse.Object('Post')

    post.set('objectId', id)

    try {
        await post.destroy()
        console.log('post was deleted')
    } catch (error) {
        console.log('error: ', error.message)
    }
}