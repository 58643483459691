import { Route, withRouter } from 'react-router-dom'
import { PostList } from './posts/PostList'
import { PostCreate } from './posts/PostCreate'
import { PostEdit } from './posts/PostEdit'
import { Login } from './auth/Login'

const ApplicationViews = (mainProps) => {

    return (
        <div>
            <Route
                exact path="/login" render={props => {
                    return (
                        <Login {...props} setUser={mainProps.setUser} />
                    )
                }}
            />
            <Route
                exact path="/" render={() => {
                    return <PostList user={mainProps.user} />
                }}
            />
            {(mainProps.user)
                ? 
                <Route
                    exact path="/postcreate" render={() => {
                        return <PostCreate user={mainProps.user} />
                    }}
                />
                :
                <></>
            }
            {(mainProps.user)
                ?
                <Route
                    exact path="/postedit/:postId([0-9a-zA-Z]+)" render={props => {
                        return <PostEdit {...props} />
                    }}
                />
                :   
                <></>
            }
        </div>
    )
}

export default withRouter(ApplicationViews);