import './Post.css'
import { useHistory } from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { formatDate } from '../../utility/datetime'

export const Post = props => {

    let history = useHistory()

    const redirect = (location, id) => {
        history.push('')
        if(id) {
            history.push(`${location}/${id}`)
        } else {
            history.push(location) 
        }
    }

    return (
        <div className='postMain'>
            <div className='postHeader'>
                {(props.user.id === props.post.authorId || props.user.isAdmin)
                    ?
                    <div className='postHeaderIcons'>
                        <div onClick={() => redirect('postedit', props.post.id)}>
                            <EditIcon />
                        </div>
                        <div onClick={() => props.handlePostDelete(props.post.id)}>
                            <DeleteIcon />
                        </div>
                    </div>
                    : 
                    <div></div>
                }
            </div>
            <h1 className='postTitle'>{props.post.title.toUpperCase()}</h1>
            <div className='postAuthor'>By {props.post.author}</div>
            <div className='postDate'>{formatDate(props.post.date)}</div>
            <div className='postContent'>{props.post.content}</div>
        </div>
    )
}