import './NavBar.css'
import MenuIcon from '@material-ui/icons/Menu'
import { useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../../images/g2g-logo-2.png'

export const NavBar = (props) => {

    const navMobileMenuRef = useRef()
    const navTitleRef = useRef()

    let history = useHistory()

    const toggleMobileMenu = () => {
        if (navMobileMenuRef.current.className.includes('hidden')) {
            navMobileMenuRef.current.className = navMobileMenuRef.current.className.replace(' hidden', '')
        } else {
            navMobileMenuRef.current.className += ' hidden'
        }

        toggleBackArrow()
    } 

    const toggleBackArrow = () => {
        if(document.getElementById('backArrow')) {
            if(document.getElementById('backArrow').className.includes('hidden')) {
                document.getElementById('backArrow').className = document.getElementById('backArrow').className.replace(' hidden', '')
            } else {
                document.getElementById('backArrow').className += ' hidden'
            }
        }
    }

    const redirect = (location) => {
        history.push(`/${location}`)
    }

    const logout = () => {
        props.setUser({
            id: 0,
            firstName: '',
            lastName: '',
            isAdmin: false
        })
        redirect('')
    }

    const isLoggedIn = useCallback(() => {
        return props.user.id !== 0
    }, [props.user.id])

    useEffect(() => {
        if(isLoggedIn()) {
            navTitleRef.current.className = "navTitleLoggedIn"
        } else {
            navTitleRef.current.className = "navTitle"
        }
    }, [props, isLoggedIn])

    return (
        <div className='navBarMain'>
            <div className='navMobileMenuIcon' onClick={toggleMobileMenu}>
                <MenuIcon
                    style={{ fontSize: 30 }}
                />
            </div>
            {(isLoggedIn())
                ?
                <div className='navMobileMenu hidden' id='navMobileMenu' ref={navMobileMenuRef}>
                    <div onClick={() => redirect('postcreate')}>Create post</div>
                    <div className='navMobileMenuLastElement' onClick={() => logout()}>Logout</div>
                </div>
                :
                <div className='navMobileMenu hidden' id='navMobileMenu' ref={navMobileMenuRef}>
                    <div className='navMobileMenuLastElement' onClick={() => redirect('login')}>Login</div>
                </div>
            }
            <div className='logoContainer'>
                <a href='https://gear2go.us'>
                    <img className='logoImg' src={logo} alt='Gear2Go Logo'></img>
                </a>
            </div>
            <div className='navTitle' ref={navTitleRef}>SHARE WHAT YOU LOVE BLOG</div>
            <div className='navDesktop'>
                {(isLoggedIn())
                    ?
                    <div className='navDesktopLinks'>
                        <div onClick={() => redirect('postcreate')}>Create post</div>
                        <div onClick={() => logout()}
                        >Logout</div>
                    </div>
                    :  
                    <div className='navDesktopLinks'>
                        <div onClick={() => redirect('login')}>Login</div>
                    </div>
                }
            </div>
        </div>
    )
}