import './Auth.css'
import { useRef, useEffect, useState } from 'react'
import { loginUser } from '../../utility/APImanager'
import Parse from 'parse/dist/parse.min.js'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const Login = (props) => {

    const [credentials, setCredentials] = useState(() => {
        return {
            username: '',
            firstName: '',
            lastName: ''
        }
    })

    const [firstLogin, setFirstLogin] = useState(() => false)

    let history = useHistory()

    const loginMainRef = useRef()
    const usernameInputRef = useRef()
    const passwordInputRef = useRef()

    useEffect(() => {
        loginMainRef.current.style.height = `${window.innerHeight - 180}px`
    }, [props])

    const updateCredentialsState = event => {
        const newCredentials = {
            ...credentials
        }
        newCredentials[event.target.id] = event.target.value
        setCredentials(newCredentials)
    }

    const handleLogin = async () => {
        const inputCredentials = {
            email: credentials.username.toLowerCase(),
            password: credentials.password 
        }

        const loginRes = await loginUser(inputCredentials)

        if(loginRes.valid) {
            // clear username and password inputs
            usernameInputRef.current.value = ''
            passwordInputRef.current.value = ''

            // check if the credentials are in the blog's database
            const userQuery = new Parse.Query('AuthUser')
            userQuery.contains('username', credentials.username)

            // login was successful and it's not the user's first time logging in
            if (await userQuery.first()) {
                const user = await userQuery.first()
                props.setUser({
                    objectId: user.id,
                    firstName: user.attributes.firstName,
                    lastName: user.attributes.lastName,
                    isAdmin: user.attributes.isAdmin
                })
                redirect('')
            } else {
                // if we can't find the username in the database then we want to get the user's
                // first and last name and create a new user on the next screen
                setFirstLogin(true)
            }
        } else {
            window.alert('Invalid username and password. Please try again.')
        }
    }

    const registerUser = async () => {
        const newUser = new Parse.Object('AuthUser')
        newUser.set('username', credentials.username)
        newUser.set('firstName', credentials.firstName)
        newUser.set('lastName', credentials.lastName)

        try {
            await newUser.save()
            console.log('new user was created')
            redirect('')
        } catch (error) {
            console.log('error: ', error.message)
            window.alert('There was an error with registration. Please try again.')
        }
    }

    const redirect = (location) => {
        history.push(`/${location}`)
    }

    return (
        <div
            className='loginMain'
            ref={loginMainRef}
        >
            {(!firstLogin)
            ?
            <div className='loginInnerContainer'>
                <div className='loginHeader1'>
                    Login
                </div>
                <div className='flex'>
                    <div className='labels'> 
                        <div className='label'>Username</div>
                        <div className='label'>Password</div>
                    </div>
                    <div className='inputs'>
                        <input
                            id='username'
                            type='text'
                            className='loginInput'
                            onChange={updateCredentialsState}
                            ref={usernameInputRef}
                        />
                        <input
                            id='password'
                            type='password'
                            className='loginInput' 
                            onChange={updateCredentialsState}     
                            ref={passwordInputRef}
                        />
                    </div>
                </div>
                <button
                    className='loginBtn'
                    onClick={handleLogin}
                    >Login
                </button>
            </div>
            :
            <div className='loginInnerContainer'>
                <div className='loginHeader2'>
                    Since this is your first time logging in we need just a little
                    more information from you.
                </div>
                <div className='flex'>
                    <div className='labels'>
                        <div className='label'>First Name</div>
                        <div className='label'>Last Name</div>
                    </div>
                    <div className='inputs'>
                        <input
                            id='firstName'
                            type='text'
                            className='loginInput'
                            onChange={updateCredentialsState}
                        />
                        <input
                            id='lastName'
                            type='text'
                            className='loginInput' 
                            onChange={updateCredentialsState}     
                        />
                    </div>
                </div>
                <button
                    className='loginBtn'
                    onClick={registerUser}
                    >Submit
                </button>
            </div>
            }
        </div>
    )
}