import "./App.css";
import ApplicationViews from "./components/ApplicationViews";
import { NavBar } from "./components/nav/NavBar";
import { useEffect, useRef, useState } from "react";
import Parse from "parse/dist/parse.min.js";
import background1 from "./images/bg1.png";
import background2 from "./images/bg2.png";
import background3 from "./images/bg3.png";

const PARSE_APPLICATION_ID = "1MBhZs2tvVHWtk1cSTQ4jhKqDW8tLH8dmMWsuvuV";
const PARSE_HOST_URL = "https://parseapi.back4app.com/";
const PARSE_JAVASCRIPT_KEY = "4LCrs5WPLM2TQ0M1EVvw3gBo816iozJtBBQ7hlSu";
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

export const App = () => {
  const [user, setUser] = useState(() => {
    return {
      id: 0,
      firstName: "",
      lastName: "",
      isAdmin: false,
    };
  });

  const mainRef = useRef();

  useEffect(() => {}, [user]);

  const closeMobileMenu = (event) => {
    if (typeof event.target.className == "string") {
      const mobileMenu = document.getElementById("navMobileMenu");
      if (
        !event.target.className.includes("navMobileMenuIcon") &&
        !mobileMenu.className.includes("hidden")
      ) {
        mobileMenu.className = mobileMenu.className += " hidden";
      }
    }
  };

  return (
    <div className="main" ref={mainRef} onClick={closeMobileMenu}>
      <NavBar user={user} setUser={setUser} />
      <div className="mainView">
        <ApplicationViews user={user} setUser={setUser} />
        <div className="mainBackgroundImgContainer">
          {/* <img src={background1} className='mainBackgroundImg1' alt='Forest' />
          <img src={background2} className='mainBackgroundImg2' alt='Forest' />
          <img src={background3} className='mainBackgroundImg3' alt='Forest' /> */}
        </div>
      </div>
    </div>
  );
};
