import './Post.css'
import { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createPost } from '../../utility/APImanager';

export const PostCreate = (props) => {

    const [post, setPost] = useState({}) 

    let history = useHistory()

    const postCreateMainRef = useRef()

    useEffect(() => {
        postCreateMainRef.current.style.height = `${window.innerHeight - 160}px`
    }, [])

    const handleChange = event => {
        const newPost = {
            ...post
        }
        newPost[event.target.id] = event.target.value
        setPost(newPost)
    }

    const handleSubmit = () => {
        createPost(props.user.id, post.title, post.content).then(res => {
            redirect('')
        })
    }

    const redirect = (location, id) => {
        if(id) {
            history.push(`${location}/${id}`)
        } else {
            history.push(location) 
        }
    }

    return (
        <div className='postCreateMain' ref={postCreateMainRef}>
            <div className='postHeader'>
                <div id='backArrow' className='postHeaderBackIcon' onClick ={() => redirect('')}>
                    <ArrowBackIcon />
                </div>
                <div className='postHeaderTitle'>Create a post</div>
            </div>
            <input
                type='text'
                id='title'
                className='postCreateTitle-input'
                onChange={handleChange}
                placeholder='Title...'
            ></input>
            <textarea
                id='content' 
                className='postCreateContent-input' 
                onChange={handleChange}
                placeholder='Your story...'
            ></textarea>
            <button className='postCreate-submitBtn' onClick={handleSubmit}>Submit</button>
        </div>
    )
}

